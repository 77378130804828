import React, { useEffect, useMemo } from 'react';
import { FiShoppingBag } from 'react-icons/fi';
import { Box, Flex, Link, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Spinner } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';

import { BasketProductElement } from '~/components/basket-partner-element/basket-product-element';
import { ButtonCore, TypographyCore } from '~/components/core-components';

import { useBasketMemory } from '~/lib/cart/hooks';
import { setBasket } from '~/lib/cart/slice';
import { useAppDispatch, useAppSelector } from '~/lib/hooks';

import styles from './basket-block.module.css';

export const BasketBlock = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const selectedProducts = useAppSelector((state) => state.cart.data);
    const promocode_discount = useAppSelector((state) => state?.cart?.promocode.discount);
    const amountOfBasket = useMemo(
        () => selectedProducts?.reduce((acc, item) => (item.disabled ? acc : acc + item.price * item.quantity), 0),
        [selectedProducts],
    );
    const { loading, setLoading } = useBasketMemory();

    useEffect(() => {
        const storedCart = localStorage.getItem('cart');

        if (storedCart) {
            dispatch(setBasket(JSON.parse(storedCart)));
        }
        setLoading(false);
    }, []);

    if (!selectedProducts.length) {
        return (
            <Link as={NextLink} href='/cart' _hover={{ textDecoration: 'none' }}>
                <ButtonCore
                    view='accent'
                    size='large'
                    leftAddon={loading ? <Spinner size='xs' color='rgba(235, 241, 237, 1)' /> : <FiShoppingBag size='24px' />}
                />
            </Link>
        );
    }

    return (
        <Popover trigger='hover' offset={[-100, 0]} autoFocus={false} returnFocusOnClose={false}>
            {({ onClose }) => (
                <>
                    <PopoverTrigger>
                        <ButtonCore className={styles['cart-button']} leftAddon={<FiShoppingBag size='24px' />} view='accent' size='large'>
                            {amountOfBasket ? (
                                <Flex justifyContent={'space-between'} w={'100%'}>
                                    <TypographyCore variant='span' useSeparate={true} className={styles['typography-ellipse']}>
                                        {promocode_discount
                                            ? parseFloat(amountOfBasket.toFixed(10)) - parseFloat(promocode_discount.toFixed(10))
                                            : parseFloat(amountOfBasket.toFixed(10))}
                                    </TypographyCore>
                                    <TypographyCore variant='span'>&nbsp;₽</TypographyCore>
                                </Flex>
                            ) : null}
                        </ButtonCore>
                    </PopoverTrigger>
                    <Portal>
                        <div className={styles['portal']}>
                            <PopoverContent pt='20px' pr='12px' pb='20px' pl='12px' w={'400px'} borderRadius='12px' border={'none'}>
                                <PopoverBody>
                                    <Flex justifyContent='space-between' mb='20px' alignItems={'center'}>
                                        <TypographyCore size='l' view='bold'>
                                            Корзина
                                        </TypographyCore>
                                    </Flex>
                                    <Box mb='44px' className={styles['cart-content']}>
                                        {selectedProducts.map((product, index) => (
                                            <BasketProductElement key={product.name} product={product} index={index} />
                                        ))}
                                    </Box>
                                    <Flex justifyContent='space-between' mb='12px'>
                                        <TypographyCore size='m' view='medium' color='secondary'>
                                            Сумма заказа
                                        </TypographyCore>
                                        <Flex>
                                            <TypographyCore size='m' view='bold' useSeparate={true}>
                                                {parseFloat(amountOfBasket.toFixed(10))}
                                            </TypographyCore>
                                            <TypographyCore size='m' view='bold' variant='span'>
                                                &nbsp;₽
                                            </TypographyCore>
                                        </Flex>
                                    </Flex>
                                    {promocode_discount ? (
                                        <>
                                            <Flex justifyContent='space-between' mb='12px'>
                                                <TypographyCore size='m' view='medium' color='primary'>
                                                    Промокод
                                                </TypographyCore>
                                                <Flex>
                                                    <TypographyCore size='m' view='medium' color='green-brand' useSeparate={true}>
                                                        {parseFloat(promocode_discount.toFixed(10))}
                                                    </TypographyCore>
                                                    <TypographyCore variant='span' size='m' view='medium' color='green-brand'>
                                                        &nbsp;₽
                                                    </TypographyCore>
                                                </Flex>
                                            </Flex>
                                            <Flex justifyContent='space-between' mb='12px'>
                                                <TypographyCore size='m' view='medium' color='primary'>
                                                    Итого
                                                </TypographyCore>
                                                <Flex>
                                                    <TypographyCore size='m' view='bold' color='primary' useSeparate={true}>
                                                        {parseFloat(amountOfBasket.toFixed(10)) - parseFloat(promocode_discount.toFixed(10))}
                                                    </TypographyCore>
                                                    <TypographyCore variant='span' size='m' view='bold' color='primary'>
                                                        &nbsp;₽
                                                    </TypographyCore>
                                                </Flex>
                                            </Flex>
                                        </>
                                    ) : null}
                                    <ButtonCore
                                        view='primary'
                                        size='large'
                                        onClick={() => {
                                            router.push('/cart');
                                            onClose();
                                        }}
                                        className={styles.button}
                                    >
                                        Перейти в корзину
                                    </ButtonCore>
                                </PopoverBody>
                            </PopoverContent>
                        </div>
                    </Portal>
                </>
            )}
        </Popover>
    );
};
