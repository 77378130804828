import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { removeArrayItem, updateArrayItem } from '~/utils/general';

export interface BasketElement {
    name: string;
    id: number;
    price: number;
    quantity: number;
    src: string;
    partnerId: number;
    disabled?: boolean;
}

export const initialState: { data: Array<BasketElement>; promocode: { id: string; code: string; discount: number | null } } = {
    data: [],
    promocode: {
        id: '',
        code: '',
        discount: null,
    },
};

export const CartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addNewElement: (state, action: PayloadAction<BasketElement>) => {
            state.data.push(action.payload);
        },
        increaseElement: (state, action: PayloadAction<{ id: number }>) => {
            const updatedElementIndex = state.data.findIndex((el) => el.id === action.payload.id);
            state.data = updateArrayItem(state.data, updatedElementIndex, {
                ...state.data[updatedElementIndex],
                quantity: state.data[updatedElementIndex].quantity + 1,
            });
        },
        decreaseElement: (state, action: PayloadAction<{ id: number }>) => {
            const updatedElementIndex = state.data.findIndex((el) => el.id === action.payload.id);
            state.data =
                state.data[updatedElementIndex].quantity - 1
                    ? updateArrayItem(state.data, updatedElementIndex, {
                          ...state.data[updatedElementIndex],
                          quantity: state.data[updatedElementIndex].quantity - 1,
                      })
                    : removeArrayItem(state.data, updatedElementIndex);
        },
        setBasket: (state, action: PayloadAction<BasketElement[]>) => {
            state.data = action.payload;
        },
        setDisabledElement: (state, action: PayloadAction<{ id: number; disabled: boolean }>) => {
            const updatedElementIndex = state.data.findIndex((el) => el.id === action.payload.id);

            state.data = updateArrayItem(state.data, updatedElementIndex, {
                ...state.data[updatedElementIndex],
                disabled: action.payload.disabled,
            });
        },
        updateElementPrice: (state, action: PayloadAction<{ id: number; price: number }>) => {
            const updatedElementIndex = state.data.findIndex((el) => el.id === action.payload.id);
            state.data = updateArrayItem(state.data, updatedElementIndex, {
                ...state.data[updatedElementIndex],
                price: action.payload.price,
            });
        },
        removeElement: (state, action: PayloadAction<{ id: number }>) => {
            const updatedElementIndex = state.data.findIndex((el) => el.id === action.payload.id);
            state.data = removeArrayItem(state.data, updatedElementIndex);
        },
        resetBasket: (state) => {
            state.data = initialState.data;
            state.promocode = initialState.promocode;
        },
        setPromocode: (state, action: PayloadAction<{ id: string; code: string; discount: number }>) => {
            state.promocode.id = action.payload.id;
            state.promocode.code = action.payload.code;
            state.promocode.discount = action.payload.discount;
        },
        resetPromocode: (state) => {
            state.promocode = initialState.promocode;
        },
    },
});

export const {
    addNewElement,
    increaseElement,
    decreaseElement,
    setBasket,
    resetBasket,
    setDisabledElement,
    updateElementPrice,
    removeElement,
    setPromocode,
    resetPromocode,
} = CartSlice.actions;

export default CartSlice.reducer;
