import React from 'react';
import { Flex, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader } from '@chakra-ui/react';
import { env } from 'next-runtime-env';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { useAppDispatch } from '~/lib/hooks';
import { useAppSelector } from '~/lib/hooks';
import { updateUser } from '~/lib/user/slice';

const DEV_HOST = 'testsympee.ru';
const serviceHost = env('NEXT_PUBLIC_HOST') || DEV_HOST;

const DEFAULT_CITY = {
    id: 1,
    image: `https://images.${serviceHost}/cities/spb.jpg`,
    lat: 59.938784,
    lon: 30.314997,
    name: 'Санкт-Петербург',
};

interface AcceptCityProps {
    onClose: () => void;
    onSelectOtherCity: () => void;
    setSessionCity: (selectedCity: string) => void;
}

export const AcceptCity = ({ onClose, onSelectOtherCity, setSessionCity }: AcceptCityProps) => {
    const selectedCity = useAppSelector((state) => state.user.data.address);
    const dispatch = useAppDispatch();
    const handleAcceptCity = () => {
        if (selectedCity.id) {
            localStorage.setItem('city', JSON.stringify(selectedCity));
            onClose();
            setSessionCity(selectedCity.name ?? '');
        } else {
            localStorage.setItem('city', JSON.stringify(DEFAULT_CITY));
            dispatch(updateUser({ address: DEFAULT_CITY }));
            onClose();
            setSessionCity(DEFAULT_CITY.name ?? '');
        }
    };

    return (
        <PopoverContent pt='28px' pr='24px' pb='28px' pl='24px' w={'375px'} borderRadius='12px' border={'none'}>
            <PopoverCloseButton color='rgba(207, 207, 207, 1)' />
            <PopoverHeader borderBottomWidth={'none'}>
                <TypographyCore size='l' view='bold'>
                    {`Получатель подарка находится в городе ${selectedCity.name ?? DEFAULT_CITY.name}?`}
                </TypographyCore>
            </PopoverHeader>
            <PopoverBody borderBottomWidth={'none'}>
                <TypographyCore color='heavy' size='s' view='medium'>
                    Сейчас вы видите ассортимент товаров, представленный только в этом городе
                </TypographyCore>
            </PopoverBody>
            <PopoverFooter borderTopWidth={'none'}>
                <Flex gap='8px'>
                    <ButtonCore view='primary' size='large' onClick={handleAcceptCity}>
                        Все верно
                    </ButtonCore>
                    <ButtonCore view='secondary' size='large' onClick={onSelectOtherCity}>
                        Другой город
                    </ButtonCore>
                </Flex>
            </PopoverFooter>
        </PopoverContent>
    );
};
