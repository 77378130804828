'use client';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { ChevronLeftIcon, HamburgerIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerOverlay,
    Flex,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    InputProps,
    Link,
    Spacer,
    useDisclosure,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';

import { useBasketMemory } from '~/lib/cart/hooks';
import { setBasket } from '~/lib/cart/slice';
import { useAppDispatch, useAppSelector } from '~/lib/hooks';
import { addNotification } from '~/lib/notification/slice';
import { useGetPartnersQuery } from '~/lib/partners/api';
import { useGetProductsCategoriesQuery } from '~/lib/product/api';
import { setSearch } from '~/lib/product/slice';
import { useYaAuthQuery } from '~/lib/user/api';
import { useGetUserInformation } from '~/lib/user/hooks';
import { createCookies, getCookies } from '~/app/actions';

import { SympeeLogo } from '../../../public/sympee-logo';
import { ButtonCore, TypographyCore } from '../core-components';

import { BasketBlock } from './basket-block';
import { CityBlock } from './city-block';
import { MobileCityBlock } from './mobile-city-block';
import { MobileView } from './mobile-view';
import { ProfileBlock } from './profile-block';

import styles from './header.module.css';

export const Header = () => {
    const params = useParams();
    const pathname = usePathname();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const searchText = useAppSelector((state) => state.product.search);

    const user = useAppSelector((state) => state?.user?.data);
    const selectedProducts = useAppSelector((state) => state.cart.data);
    const [isCookiePresent, setIsCookiePresent] = useState<boolean | null>(null);
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });
    const { triggerUserInfo, isFetching } = useGetUserInformation();
    const [accessToken, setAccessToken] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [search, setSearchValue] = useState('');
    const handleInputChange: InputProps['onChange'] = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        dispatch(setSearch(value));
    };

    const handleKeyDownSearch = async (e?: KeyboardEvent<HTMLInputElement>) => {
        if (e?.key === 'Enter') {
            e?.preventDefault();
            if (search.length > 2) {
                router.push('/catalog/all');
            }
        }
    };

    const { data: token } = useYaAuthQuery(
        { token: accessToken ?? '' },
        {
            skip: !accessToken,
        },
    );

    const { data } = useSession();

    useEffect(() => {
        if (data?.access_token) {
            setAccessToken(data.access_token);
        }
    }, [data]);

    useEffect(() => {
        setSearchValue(searchText);
    }, [searchText]);

    useEffect(() => {
        if (token) {
            createCookies({
                name: 'token',
                value: token.data,
                secure: false,
                httpOnly: true,
            })
                .then(() => {
                    triggerUserInfo();
                })
                .catch(() =>
                    dispatch(
                        addNotification({
                            status: 'error',
                            title: 'Ошибка',
                            description: `Произошла ошибка при авторизации, перезагрузите страницу или повторите позже`,
                        }),
                    ),
                );
        }
    }, [dispatch, token, triggerUserInfo]);

    const { onReset, setLoading } = useBasketMemory();
    const { partner } = useGetPartnersQuery(
        { $filter: '' },
        {
            skip: !params.slug?.length || pathname.includes('my-gifts/'),
            selectFromResult: ({ data, isFetching }) => ({
                partner: data?.data.items.find((partner) => params.slug?.includes(partner.id.toString())),
                isFetchingPartner: isFetching,
            }),
        },
    );

    const { category } = useGetProductsCategoriesQuery(
        { $filter: '' },
        {
            skip: !pathname.includes('catalog/') || pathname.includes('partner/') || !params.slug?.length,
            selectFromResult: ({ data, isFetching, isUninitialized }) => ({
                category: data?.data.items.find((category) => params.slug?.includes(category.slug)),
                isFetchingCategory: isFetching,
                isUninitialized,
            }),
        },
    );

    const handleAcceptResetCart = () => {
        onReset();
        onClose();
    };

    useEffect(() => {
        getCookies('token').then((item) => {
            if (item) {
                setIsCookiePresent(true);
                triggerUserInfo();
            } else {
                setIsCookiePresent(false);
            }
        });
    }, [dispatch, triggerUserInfo, user]);

    useEffect(() => {
        const storedCart = localStorage.getItem('cart');

        if (storedCart) {
            dispatch(setBasket(JSON.parse(storedCart)));
        }
        setLoading(false);
    }, []);

    if (pathname === `/${params.slug?.[0]}/`) {
        return null;
    }

    return (
        <>
            {isMobile ? (
                <>
                    <header
                        className={`${styles['shadow-mobile']} ${pathname === '/' || pathname === '/catalog/' ? styles['sticky-header-mobile'] : ''}`}
                    >
                        <div className={styles.mobile}>
                            <div className={styles['mobile-header']}>
                                <Link as={NextLink} href='/' _hover={{ textDecoration: 'none' }} mb='4px'>
                                    <Icon as={SympeeLogo} w='126px' h='30px' />
                                </Link>
                                {pathname !== '/' && <MobileCityBlock />}
                            </div>
                            {pathname.includes('/catalog/') && <MobileView />}
                        </div>
                    </header>
                    <div className={styles['mobile-navigate-header']}>
                        {pathname.includes('success') || pathname === '/' || pathname === '/catalog/' ? null : (
                            <ButtonCore onClick={() => (pathname.includes('partner') ? router.push('/catalog') : router.back())}>
                                <ChevronLeftIcon w={'24px'} h={'24px'} color='rgba(0, 0, 0, 1)' />
                            </ButtonCore>
                        )}

                        <TypographyCore color='primary' size='m' view='bold' className={styles['text-line-ellipsis']}>
                            {partner?.name}
                            {pathname.includes('my-gifts') ? 'Мои подарки' : ''}
                            {pathname.includes('cart') ? 'Корзина' : ''}
                            {pathname.includes('account') ? 'Личные данные' : ''}
                            {pathname.includes('profile') ? 'Мой профиль' : ''}
                            {category?.name}
                            {pathname.includes('catalog/all') ? 'Все категории' : ''}
                        </TypographyCore>
                        <div>
                            {pathname.includes('cart') && selectedProducts.length ? (
                                <>
                                    <ButtonCore view='ghost' onClick={onOpen}>
                                        <TypographyCore size='s' view='medium' color='heavy'>
                                            Очистить
                                        </TypographyCore>
                                    </ButtonCore>
                                    <Drawer placement='bottom' isOpen={isOpen} onClose={onClose} autoFocus={false} returnFocusOnClose={false}>
                                        <DrawerOverlay />
                                        <DrawerContent borderTopLeftRadius={'32px'} borderTopRightRadius={'32px'} border={'none'}>
                                            <DrawerBody paddingTop={'32px'}>
                                                <TypographyCore size='l' view='bold' color='primary' className={styles.typography}>
                                                    Вы точно хотите очистить корзину?
                                                </TypographyCore>
                                            </DrawerBody>
                                            <DrawerFooter gap={'12px'}>
                                                <ButtonCore size='large' view='primary' onClick={onClose} style={{ width: '100%' }}>
                                                    Оставить
                                                </ButtonCore>
                                                <ButtonCore size='large' view='secondary' onClick={handleAcceptResetCart} style={{ width: '100%' }}>
                                                    Очистить
                                                </ButtonCore>
                                            </DrawerFooter>
                                        </DrawerContent>
                                    </Drawer>
                                </>
                            ) : null}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <header className={`${styles.shadow}`}>
                        <div className={styles.desktop}>{pathname !== '/' ? <CityBlock /> : null}</div>
                    </header>
                    <div className={styles['sticky-header']}>
                        <Flex justifyContent={'space-between'} mt={pathname !== '/' ? '' : '16px'} pt={'10px'} pl='0' pr='0'>
                            <Flex justifyContent={'space-between'}>
                                <div>
                                    <Link as={NextLink} href='/' _hover={{ textDecoration: 'none' }} mr='40px'>
                                        <Icon as={SympeeLogo} w={'158px'} h={'36px'} />
                                    </Link>
                                </div>
                                <div>
                                    <Flex gap='1'>
                                        <Link as={NextLink} href='/catalog/' _hover={{ textDecoration: 'none' }}>
                                            <ButtonCore leftAddon={<HamburgerIcon />} view='primary' size='large'>
                                                Каталог
                                            </ButtonCore>
                                        </Link>
                                        <Spacer />
                                        <InputGroup borderRadius={'8px'} w={'20vw'}>
                                            <InputLeftElement pointerEvents='none'>
                                                <SearchIcon color='gray.300' />
                                            </InputLeftElement>
                                            <Input
                                                variant='filled'
                                                backgroundColor='rgba(244, 244, 244, 1)'
                                                placeholder='Искать в Sympee'
                                                onChange={handleInputChange}
                                                onKeyDown={handleKeyDownSearch}
                                                value={search}
                                                h='44px'
                                                _hover={{
                                                    borderColor: 'rgba(3, 77, 33, 1)',
                                                }}
                                                _active={{
                                                    borderColor: 'rgba(3, 77, 33, 1)',
                                                }}
                                                _focusVisible={{
                                                    borderColor: 'rgba(3, 77, 33, 1)',
                                                }}
                                            />
                                        </InputGroup>
                                    </Flex>
                                </div>
                            </Flex>
                            <div>
                                <Flex gap='1'>
                                    <BasketBlock />
                                    <Spacer />
                                    <ProfileBlock isCookiePresent={isCookiePresent} isFetching={isFetching} />
                                </Flex>
                            </div>
                        </Flex>
                    </div>
                </>
            )}
        </>
    );
};
