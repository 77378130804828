/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiSlice } from '../rtk-query/api';

export const basketApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCart: builder.query<{ data: { items: any } }, void>({
            query: () => `/v1/user/cart`,
            transformErrorResponse: () => 'Возникла проблема при получении данных корзины',
        }),
        updateCart: builder.mutation<
            {
                data: any;
            },
            { product_id: number; quantity: number }
        >({
            query: (params) => ({ url: 'v1/user/cart', method: 'PUT', body: params }),
            transformErrorResponse: () => 'Возникла проблема при обновлении корзины',
        }),
        verifyPromocodes: builder.query<{ data: any }, { code: string; positions: Array<{ product_id: number; quantity: number }> }>({
            query: (params) => ({ url: `/v1/promocodes/verify`, method: 'POST', body: params }),
            transformErrorResponse: () => 'Возникла проблема при применении промокода',
        }),
    }),
});

export const { useGetCartQuery, useLazyGetCartQuery, useUpdateCartMutation, useLazyVerifyPromocodesQuery } = basketApi;
