import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Drawer, DrawerOverlay, useDisclosure } from '@chakra-ui/react';
import { env } from 'next-runtime-env';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { useAppDispatch, useAppSelector } from '~/lib/hooks';
import { updateUser } from '~/lib/user/slice';

import { MobileAcceptCity } from './mobile-accept-city';
import { MobileSelectCity } from './mobile-select-city';

import styles from './mobile-city-block.module.css';

const DEV_HOST = 'testsympee.ru';
const serviceHost = env('NEXT_PUBLIC_HOST') || DEV_HOST;

const DEFAULT_CITY = {
    id: 1,
    image: `https://images.${serviceHost}/cities/spb.jpg`,
    lat: 59.938784,
    lon: 30.314997,
    name: 'Санкт-Петербург',
};

export const MobileCityBlock = () => {
    const dispatch = useAppDispatch();
    const selectedCity = useAppSelector((state) => state.user.data.address?.name ?? '');
    const [isSelectOtherCity, setIsSelectOtherCity] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [loading, setLoading] = useState(true);
    const [sessionCity, setSessionCity] = useState('');

    const handleSelectOtherCity = () => {
        setIsSelectOtherCity(true);
    };

    const handleClosePopover = () => {
        onClose();

        dispatch(updateUser({ address: DEFAULT_CITY }));
    };

    useEffect(() => {
        const storedCity = localStorage.getItem('city');

        if (storedCity) {
            dispatch(updateUser({ address: JSON.parse(storedCity) }));
            setSessionCity(JSON.parse(storedCity));
        }
        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
        if (!sessionCity && !loading) {
            onOpen();
        }
    }, [sessionCity, loading, onOpen]);

    return (
        <>
            <ButtonCore onClick={onOpen} rightAddon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />} className={styles['button-city']}>
                <TypographyCore view='bold'>{selectedCity || 'Санкт-Петербург'}</TypographyCore>
            </ButtonCore>
            <Drawer isOpen={isOpen} placement='bottom' onClose={handleClosePopover} autoFocus={false} returnFocusOnClose={false}>
                <DrawerOverlay />

                {isSelectOtherCity || sessionCity ? (
                    <MobileSelectCity onClose={onClose} setIsSelectOtherCity={setIsSelectOtherCity} setSessionCity={setSessionCity} />
                ) : (
                    <MobileAcceptCity onClose={onClose} onSelectOtherCity={handleSelectOtherCity} setSessionCity={setSessionCity} />
                )}
            </Drawer>
        </>
    );
};
