import { useEffect, useRef, useState } from 'react';

/**
 * Хук для хранения предыдущих значений пропсов (переменных)
 */
export const usePrevious = <T>(value: T): T => {
    const ref = useRef<T>(value);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export function useCookieValue(cookieName: string) {
    const [value, setValue] = useState<null | string>(null);

    useEffect(() => {
        const getCookieValue = (cookieName: string) => {
            const cookies = document.cookie.split('; ');
            const cookie = cookies.find((c) => c.startsWith(`${cookieName}=`));
            return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
        };

        setValue(getCookieValue(cookieName));
    }, [cookieName]);

    return value;
}
